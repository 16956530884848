import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import Layout from "../components/Layout1"
import SEO from "../components/SEO"
import Header from "../components/Header"

const ThankYou = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <>
        {modal ? (
          <>
            <Link
              to={closeTo}
              className="absolute top-0 right-0 px-4 py-2 text-3xl leading-none hover:bg-gray-400"
            >
              &times;
            </Link>
            <Header className="w-full md:w-10/12 xl:w-8/12 px-4 mx-auto my-6 block" />
            <div className="w-full mx-auto mb-16">
              <h2 className="w-full bg-adobe py-4 mb-10 leading-none items-center font-sans font-bold text-white text-2xl lg:text-4xl text-center">
                Thank you!
              </h2>
              <div className="mt-2 px-10  p-with-leading">
                <p className="font-serif font-light text-black text-xl">Your form submission has been received.</p>
              </div>
            </div>
          </>
        ) : (
          <Layout>
            <link
              href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Teko:wght@300;400;500;600;700&display=swap"
              rel="stylesheet"
            ></link>
            <SEO title="Thank You" />
            <div className="w-full lg:w-8/12 mx-auto mb-16">
              <h2 className="w-full bg-adobe py-4 mb-10 leading-none items-center font-sans font-bold text-white text-2xl lg:text-4xl text-center">
                Thank you!
              </h2>
              <div className="mt-2 px-10  p-with-leading">
                <p className="font-serif font-light text-black text-xl">Your form submission has been received.</p>
                <Link className="bg-red hover:bg-adobe text-white font-bold font-serif py-2 px-8 text-lg rounded" to="/">Back to home</Link>
              </div>
            </div>
          </Layout>
        )}
      </>
    )}
  </ModalRoutingContext.Consumer>
)

export default ThankYou
